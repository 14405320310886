import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  Component,
  ViewChild,
  OnInit,
  Inject,
  TemplateRef,
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { SwUpdate } from '@angular/service-worker';
import { DOCUMENT } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { version } from '../../package.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  version = version;

  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild('updateSnackBar') private updateSnackBar: TemplateRef<any>;

  constructor(
    public snackBar: MatSnackBar,
    private router: Router,
    private swUpdate: SwUpdate,
    private dialog: MatDialog,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.sidenav.close();
        this.snackBar.dismiss();
      }
    });

    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        const snackBarRef = this.snackBar.openFromTemplate(
          this.updateSnackBar,
          {
            panelClass: ['mat-toolbar', 'mat-primary'],
          }
        );

        snackBarRef.afterDismissed().subscribe(() => this.activateUpdate());
      });
    }
  }

  openPrivacyPolicy(event: Event) {
    event.preventDefault();

    this.dialog.open(PrivacyPolicyComponent);
  }

  private activateUpdate() {
    this.swUpdate.activateUpdate().then(() => this.document.location.reload());
  }
}
