<mat-toolbar class="mat-elevation-z6 no-print">
  <mat-toolbar-row>
    <div fxHide.gt-sm="true">
      <button
        mat-icon-button
        aria-label="Open the menu"
        (click)="sidenav.toggle()"
      >
        <mat-icon>menu</mat-icon>
      </button>
    </div>
    <a class="logo-link" routerLink="/">
      <img src="assets/logo.png" alt="Evolonix" />
    </a>
    <span fxFlex="1 1 auto"></span>
    <app-nav fxLayout="row" fxLayoutGap="1.5rem" fxHide.lt-md="true"></app-nav>
  </mat-toolbar-row>
</mat-toolbar>

<mat-sidenav-container fxFlexFill>
  <mat-sidenav #sidenav>
    <mat-nav-list>
      <a mat-list-item routerLink="/" style="height: 0"></a>
      <app-nav></app-nav>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <section>
      <h1>Coming soon!</h1>
      <router-outlet></router-outlet>
    </section>

    <footer
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="1rem"
      class="no-print"
    >
      <a
        href="#"
        aria-label="Open the privacy policy"
        (click)="openPrivacyPolicy($event)"
      >
        Privacy Policy
      </a>
      <span>v{{ version }}</span>
    </footer>
  </mat-sidenav-content>
</mat-sidenav-container>

<div class="bg"></div>

<ng-template #updateSnackBar>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.5rem">
    <mat-icon>info</mat-icon>
    <span>A new version is available.</span>
    <span fxFlex="1 1 auto"></span>
    <button mat-icon-button (click)="snackBar.dismiss()">
      <mat-icon>refresh</mat-icon>
    </button>
  </div>
</ng-template>
